import {
  Box,
  Button,
  Modal,
  TableCell,
  TableRow
} from "@mui/material";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ArrowRightCircle from "../../../assets/svgs/Arrow-right-circle";
import DeleteIcon from "../../../assets/svgs/delete-icon";
import { showHideSnackbar } from "../../../store/Snackbar/actions";
import { deleteSingleTopicRequest } from "../../../store/Topics/actions";
import { boxStyle } from "../../Books/BookViewer/boxStyle";

function SingleRowTopic({ programId, topic }) {
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");

  const { messages } = useIntl();

  const {
    topic: { deleteMessage }
  } = messages;
  const history = useHistory();
  const dispatch = useDispatch();

  const goToShowTopicDetails = () => {
    isProgram
      ? history.push(
          `/programs/getprogra/${programId}/topics/${topic.id}`
        )
      : history.push(`/getprogra/${programId}/topics/${topic.id}`);
  };

  const deleteSpacificTopic = () => {
    dispatch(deleteSingleTopicRequest(programId, topic.id));
    dispatch(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: deleteMessage
      })
    );
  };

  const [openDelete, setOpenDelete] = useState({
    open: false,
    id: 0
  });
  const triggerDeleteModal = (id) => {
    setOpenDelete({
      id,
      open: true
    });
  };
  const closeDeleteModal = () =>
    setOpenDelete({
      id: 0,
      open: false
    });
  return (
    <>
      <TableRow
        className="TopicRow"
        key={topic.id}
        sx={{
          bgcolor: "#EAF4FF",
          borderBottom: "10px solid #ffffff",
          boxSizing: "content-box",
          width: "100%"
        }}
      >
        <TableCell
          sx={{
            borderRadius: "15px 0px 0px 15px",
            padding: "10px",
            maxWidth: "500px",
            wordWrap: "break-word",
            whiteSpace: "normal"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem"
            }}
          >
            <p
              style={{
                fontSize: "1.3rem",
                margin: 0
              }}
            >
              {topic.title}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  wordWrap: "break-word",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "#9F9C9C"
                }}
              >
                {parse(topic.content, {
                  replace: (domNode) => {
                    if (domNode.name === "img") {
                      domNode.attribs.style =
                        "max-width: 150px; height: auto;";
                    }
                  }
                })}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell sx={{ alignContent: "flex-start" }}>
          <div style={{ color: "#9F9C9C" }}>
            {topic.created_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")}
          </div>
        </TableCell>
        <TableCell
          sx={{
            borderRadius: "0 15px 15px 0"
          }}
        >
          <div
            style={{
              display: "flex",
              color: "#9f9c9c",
              gap: "3rem",
              justifyItems: "center",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "10px"
            }}
            align="right"
          >
            {isProgram && (
              <DeleteIcon
                onClick={() => triggerDeleteModal(topic.id)}
              />
            )}
            <ArrowRightCircle onClick={goToShowTopicDetails} />
          </div>
        </TableCell>
      </TableRow>
      {!!openDelete.open && (
        <Modal
          open={openDelete.open}
          onClose={closeDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...boxStyle,
              maxHeight: "90%",
              height: "40svh",
              width: "45%",
              minWidth: "45%",
              overflowY: "auto",
              border: "1px solid black",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <h4>Confirm Delete This Topic!</h4>
            <br />
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "end",
                width: "100%"
              }}
            >
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={closeDeleteModal}
              >
                Cancel
              </Button>
              <Button
                style={{ color: "white" }}
                variant="contained"
                color="error"
                onClick={deleteSpacificTopic}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

SingleRowTopic.propTypes = {
  topic: PropTypes.object.isRequired,
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

export default SingleRowTopic;
