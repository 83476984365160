import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import "./StudentsOrTopics.scss";

function StudentsOrTopics() {
  const { pathname } = useLocation();
  const isTopic = pathname.toLowerCase().includes("topics");
  const isStudents = pathname.toLowerCase().includes("students");
  const isProgram = pathname.includes("program");
  const isInViewTopic = pathname.toLowerCase().includes("getprogra");
  console.log("isProgram", pathname.split("/")[2]);
  const [programId, setprogramId] = useState();
  useEffect(() => {
    setprogramId(pathname.split("/")[2]);
  }, [pathname]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "5%"
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-around",
            width: `${isInViewTopic ? "100%" : "80%"}`,
            backgroundColor: "#F8F8F9", // Replace with a valid color
            borderRadius: 2
          }}
        >
          {isProgram && (
            <div className="student">
              <Link
                className={`fs-4 fw-bold ${
                  isStudents ? "text-primary" : ""
                }`}
                to={ROUTE_PATHS.StudentsList}
              >
                Students
              </Link>
            </div>
          )}
          <div className="topic">
            <Link
              className={`fs-4 fw-bold ${
                isTopic ? "text-primary" : ""
              }`}
              to={
                isProgram
                  ? ROUTE_PATHS.Topics
                  : `${ROUTE_PATHS.StudentsTopics}/${programId}`
              }
            >
              Topics
            </Link>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default StudentsOrTopics;
