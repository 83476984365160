import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { axiosInstance, customer_portal } from "../../network/apis";
import History from "../../routes/History";
import { getDirectorSingleBookAction } from "../../store/Books/actions";
import { showHideLoader } from "../../store/Loader/actions";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import {
  capitalizeFirstLetter,
  splitOnCapitals
} from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import "./style.css";

export default function BookCheckout() {
  const params = useParams();
  const { messages } = useIntl();
  const {
    checkoutForm: { checkout, apply }
  } = messages;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.login);

  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );

  useEffect(() => {
    if (!subscribeBookSelected) {
      dispatch(getDirectorSingleBookAction(params?.id));
    }
  }, [subscribeBookSelected]);

  const billingAddressDefaults = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  };
  const [billingAddress, setBillingAddress] = useState(
    billingAddressDefaults
  );
  const paymentDefaults = {
    card_number: "",
    expiry: "",
    cvv: ""
  };
  const [paymentData, setPaymentData] = useState(paymentDefaults);
  function handleBillingAddressChange(field, value) {
    setBillingAddress((prev) => ({
      ...prev,
      [field]: value
    }));
  }

  const defaultPromoResult = {
    success: false,
    discounted_price: -1,
    discount_price: 0,
    promo_code: ""
  };
  const [promocodeInput, setPromocodeInput] = useState("");
  const [appliedPromoCode, setappliedPromoCode] = useState(
    defaultPromoResult
  );
  const isFreePromocode = appliedPromoCode?.discounted_price === 0;
  const getDiscountedPrice = async (promoName) => {
    try {
      dispatch(showHideLoader(true));
      const { data } = await axiosInstance.get(
        `${customer_portal}/promo_codes/request_discounted_price?name=${promoName}&book_id=${params?.id}`
      );
      setappliedPromoCode(data);
      dispatch(showHideLoader(false));
    } catch (error) {
      setappliedPromoCode(defaultPromoResult);
      console.log(error);
      throw error;
    }
  };

  const createSubscribtion = async () => {
    try {
      dispatch(showHideLoader(true));
      const { data } = await axiosInstance.post(
        `${customer_portal}/payment/charge_credit_card`,
        {
          payment: paymentData,
          bill_to: billingAddress,
          ship_to: billingAddress,
          name: `${billingAddress.firstName} ${billingAddress.lastName}`,
          amount: appliedPromoCode.success
            ? appliedPromoCode?.discounted_price
            : subscribeBookSelected?.price,
          customer: {
            email: user.email
          },
          book_id: params?.id,
          promo_code_name: appliedPromoCode.promo_code
        }
      );
      dispatch(showHideLoader(false));
      if (data.message) {
        dispatch(
          showHideSnackbar({
            isOpen: false,
            type: "success",
            message: data.message
          })
        );
        History.push(`${ROUTE_PATHS.bookProfile}/${params?.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Container>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createSubscribtion();
          }}
        >
          <Row>
            <h3 className="mb-5">{checkout}</h3>
            <Col md={8} xs={12} className="mx-auto mb-5">
              <div>
                <h4>Billing Address</h4>
                <div className="input-container">
                  {Object.entries(billingAddressDefaults).map(
                    ([key, _]) => {
                      return (
                        <InputWithLabel
                          required={!isFreePromocode}
                          key={key}
                          name={splitOnCapitals(key)}
                          onChange={(e) =>
                            handleBillingAddressChange(
                              key,
                              e.target.value
                            )
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <br />
              <h4>Card Data</h4>
              <br />

              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexWrap: "wrap"
                }}
              >
                <CardNumberInput
                  required={!isFreePromocode}
                  style={{ margin: "7px", width: "100%" }}
                  onChange={(value) => {
                    const cardNumbers = value?.replaceAll(" ", "");
                    setPaymentData((oldPaymetData) => {
                      return {
                        ...oldPaymetData,
                        card_number: cardNumbers
                      };
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "1rem"
                  }}
                >
                  <CVVInput
                    required={!isFreePromocode}
                    style={{ margin: "7px", width: "100%" }}
                    onChange={(value) => {
                      setPaymentData((oldPaymetData) => {
                        return {
                          ...oldPaymetData,
                          cvv: value
                        };
                      });
                    }}
                  />
                  <ExpiryDateInput
                    required={!isFreePromocode}
                    style={{ margin: "7px", width: "100%" }}
                    onChange={(value) => {
                      const cardDate = value
                        ?.replaceAll("/", "")
                        ?.replaceAll(" ", "");
                      setPaymentData((oldPaymetData) => {
                        return {
                          ...oldPaymetData,
                          expiry: cardDate
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={4} xs={12} className="mx-auto mb-5">
              <div
                style={{
                  width: "90%",
                  backgroundColor: "#F0F0F0",
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "1rem",
                  margin: "1rem",
                  borderRadius: "5px",
                  padding: "1rem"
                }}
              >
                <div style={{ width: "5rem", height: "6rem" }}>
                  <img
                    src={subscribeBookSelected?.cover_photo}
                    alt="cover photo"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <h4>{subscribeBookSelected?.title}</h4>
              </div>
              <div
                style={{
                  width: "90%",
                  backgroundColor: "#F0F0F0",
                  margin: "1rem",
                  borderRadius: "5px",
                  padding: "1.5rem"
                }}
              >
                <h4>Promo Code</h4>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <input
                    style={{
                      height: "3rem",
                      border: "none",
                      borderRadius: "7px",
                      width: "70%",
                      padding: "10px"
                    }}
                    type="text"
                    placeholder="Enter Coupon"
                    value={promocodeInput}
                    onChange={(e) =>
                      setPromocodeInput(e.target.value)
                    }
                  />
                  <button
                    style={{
                      backgroundColor: "#0c41af",
                      width: "5rem",
                      minWidth: "3rem",
                      borderRadius: "7px",
                      color: "white"
                    }}
                    type="button"
                    onClick={() => {
                      getDiscountedPrice(promocodeInput);
                    }}
                  >
                    {apply}
                  </button>
                </div>
                <Row>
                  <Col className={`mx-auto`} sm={12} md={12}></Col>
                </Row>
              </div>
              <div
                style={{
                  width: "90%",
                  backgroundColor: "#F0F0F0",
                  margin: "1rem",
                  borderRadius: "5px",
                  padding: "1rem"
                }}
              >
                <div>
                  <h4>Summary</h4>
                </div>
                <br />
                <div
                  style={{
                    padding: "1rem"
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <span>Original Price:</span>
                    <span> ${subscribeBookSelected?.price}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>Discount:</span>
                    <span>
                      ${appliedPromoCode?.discount_price ?? 0}
                    </span>
                  </div>
                  <br />
                  <div className="d-flex justify-content-between">
                    <span>Total Price</span>
                    <span className="m-1">
                      {appliedPromoCode.success
                        ? `$${appliedPromoCode?.discounted_price}`
                        : `$${subscribeBookSelected?.price}`}
                    </span>
                  </div>
                </div>
                <button
                  style={{
                    margin: "auto",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "7px",
                    color: "white",
                    backgroundColor: "#0E4DCE"
                  }}
                  type="submit"
                >
                  Subscribe
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  );
}

const InputWithLabel = ({ name, onChange, required }) => {
  const label = (() => {
    if (name == "last_name") return "Last Name";
    if (name == "first_name") return "First Name";
    return capitalizeFirstLetter(name);
  })();
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        required={required}
        id={name}
        type="text"
        name={name}
        placeholder={""}
        onChange={onChange}
      />
    </div>
  );
};

InputWithLabel.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

const CardNumberInput = ({ onChange, style, required }) => {
  const [input, setInput] = useState("");

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    let formattedValue = "";

    // Format the number with spaces every 4 digits
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && (i + 1) % 4 === 0) {
        formattedValue += " ";
      }
      formattedValue += value[i];
    }

    setInput(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div style={style} className="card-input form-group">
      <label htmlFor="CardNumber">Card Number</label>
      <input
        required={required}
        id="CardNumber"
        type="text"
        value={input}
        onChange={handleChange}
        maxLength="19"
        minLength={19}
        placeholder="Card Number"
      />
    </div>
  );
};
CardNumberInput.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  required: PropTypes.bool
};

const CVVInput = ({ onChange, style, required }) => {
  const [input, setInput] = useState("");

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    const inputValue = value.slice(0, 4);
    setInput(inputValue); // Limit to 4 digits max
    onChange(inputValue);
  };

  return (
    <div className="card-input form-group" style={style}>
      <label htmlFor="CVV">CVV</label>
      <input
        required={required}
        id="CVV"
        type="text"
        value={input}
        onChange={handleChange}
        maxLength="4"
        minLength={3}
        placeholder="CVV"
      />
    </div>
  );
};
CVVInput.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  required: PropTypes.bool
};

const ExpiryDateInput = ({ onChange, style, required }) => {
  const [input, setInput] = useState("");

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    console.log(value, value.length);

    let formattedValue = "";

    // Format the number with spaces every 2 digits for months, and every 4 digits for years
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedValue += " / ";
      }
      formattedValue += value[i];
    }

    setInput(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div className="card-input form-group" style={style}>
      <label htmlFor="ExpiryDate">Expiry Date</label>
      <input
        required={required}
        id="ExpiryDate"
        type="text"
        value={input}
        onChange={handleChange}
        maxLength="7"
        minLength={7}
        placeholder="MM/YY"
      />
    </div>
  );
};

ExpiryDateInput.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  required: PropTypes.bool
};
