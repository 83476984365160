import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FolderIcon from "../../assets/svgs/folder-icon";
import SearchIcon from "../../assets/svgs/search-icon";
import StudentsOrTopics from "../../components/StudentsOrTopics";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import TableTopics from "./TableTopics";
import "./Topics.scss";
import {
  getAllTopicsRequest,
  getCurrentProgramRequest,
  getCustomerTopicsRequest
} from "../../store/Topics/actions";
import { useIntl } from "react-intl";
import {
  useLocation,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
function Topics() {
  const [searchName, setSearchName] = useState("");
  const history = useHistory();
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const { programId } = useParams();

  const currentProgram = useSelector(
    (state) => state.topics.currentProgram
  );
  const navigToAddNewTopic = () => {
    history.push(`/programs/${currentProgram?.id}/topic/AddNewTopic`);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    isProgram
      ? dispatch(getCurrentProgramRequest())
      : dispatch(getCustomerTopicsRequest(programId));
  }, []);

  useEffect(() => {
    if (currentProgram.id)
      dispatch(getAllTopicsRequest(currentProgram?.id));
  }, [currentProgram?.id]);

  const AllTopics = useSelector((state) => state.topics.TopicsList);
  const [editArrayTopics, setEditArrayTopics] = useState(AllTopics);

  useEffect(() => {
    if (searchName) {
      let editArray = AllTopics.filter((topic) => {
        return topic.title
          .toLowerCase()
          .includes(searchName.toLowerCase());
      });
      setEditArrayTopics(editArray);
    } else {
      setEditArrayTopics(AllTopics);
    }
  }, [searchName, AllTopics]);

  const { messages } = useIntl();
  const {
    topic: { addTopicButton }
  } = messages;

  return (
    <>
      <Container>
        <StudentsOrTopics />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%"
            }}
          >
            <button
              className="d-flex gap-2 align-items-center"
              onClick={navigToAddNewTopic}
              disabled={!isProgram}
            >
              <FolderIcon />
              {addTopicButton}
            </button>
            <Box>
              <form>
                <div className="search-container">
                  <input
                    className="search-field"
                    type="text"
                    placeholder="search"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                  <SearchIcon />
                </div>
              </form>
            </Box>
          </Box>
        </Box>
        <TableTopics
          programId={isProgram ? currentProgram?.id : programId}
          AllTopics={editArrayTopics}
        />
      </Container>
    </>
  );
}

export default Topics;
